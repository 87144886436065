<template>
    <div id="color-bkg"></div>
	<div class="wrapper" :style="store.isMobile && store.windowHeight && `height: ${store.windowHeight}px`">
		<div class="content">
			<div class="card">
				<div class="card-body">
					<div class="x-wrap" @click="close">
						<img src="/svgs/x-vector.svg">
					</div>

					<h2>Toggle Status</h2>
					<br />
                    <div style="display: flex; flex-direction: row; gap: .5rem;">
                        <button class="btn" @click="close">Cancel</button>
                        <button class="btn" @click="toggle">Toggle</button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useStore } from '@/store'
import { defineEmits } from 'vue';
const emit = defineEmits(['close', 'toggle']);

const store = useStore();

const close = () => {
	emit('close')
};

const toggle = () => {
    emit('toggle')
}

</script>

<style type="text/css" scoped>
#color-bkg {
	position: fixed;
	width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,1);
    opacity: .2;
}

.x-wrap {
	position: fixed;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 100vh;
	width: 100vw;
	z-index: 5;
	backdrop-filter: blur(10px);
}

.content {
	text-align: center;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	width: 22rem;
	border-radius: 20px;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

.form-control {
	background-color: #EBEBEB;
	width: 100%;
	box-sizing: border-box;
	border: none;
	padding: .85rem;
	color: #000;
	border-radius: 5px;
	outline: none !important;
}
</style>