import { createRouter, createWebHistory } from 'vue-router';
import { getAuthToken } from '../services/auth-service';

// 1. Define route components.
// These can be imported from other files
// const Home = { template: '<div>Home</div>' }
import Dashboard from '@/components/Dashboard';
import Create from '@/components/Create';
import Login from '@/components/Login';
import Stats from '@/components/Stats';

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/login', component: Login },
  { path: '/', meta: {requiresAuth: true}, component: Dashboard },
  { path: '/create', meta: {requiresAuth: true}, component: Create },
  { path: '/tunecast/:tunecastId', meta: {requiresAuth: true}, component: Create },
  { path: '/stats/:tunecastId', meta: {requiresAuth: true}, component: Stats }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
console.log('the router is...?')
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!getAuthToken();

  if (requiresAuth && !isAuthenticated) {
    next('/login'); // Redirect to login page if not authenticated
  } else {
    next();
  }
});


export default router;