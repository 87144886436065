import { createApp } from 'vue'
import { createPinia } from 'pinia';
const pinia = createPinia();
import router from '@/router';
import App from './App.vue'

let app = createApp(App)
app.use(pinia);
app.use(router);
console.log('got the router', router);

app.mount('#app')

/**
TODO LIST

https://codepen.io/recursivecodes/pen/YzaLpdJ

[] live stream
	[x] toggle playback
	[] offset start time by voice over segments, also store on server
	[] socket listeners for starting + stopping audio
	[] server side socket events on push to talk / release
	[] broadcast sdk frontend

[x] ifvisible implementation for stats
[] end tunecast view
[] stop audio when ending
[] shopify implementation
[] moderation tools
[] vip users - code / chat
[] email capture
[] liking for tracks

[x] mobile spotify countdown on content view


[] resync?

[-] fix streaming ~ mobile spotify | player.activeElement() seems to work
// https://developer.spotify.com/documentation/web-playback-sdk/reference#spotifyplayeractivateelement

[x] update mobile playlist tracklist 
[x] clean up FAQ mobile view, janky scrolling when expanded
[x] mobile sliding
[x] update playlist tracklist
[x] wait to start audio until countdown ends
[x] countdown | tunecast states
[x] socketio - chat implementation
[x] filter host messages
[x] faq popup
[x] current playing track
[x] streaming (apple/spotify)
*/