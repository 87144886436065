import axios from 'axios';
import { BACKEND_URL } from './config';

export async function login(username, password) {
    const response = await axios.post(`${BACKEND_URL}/admin/login`, { username, password });
    if(response.data.token) setAuthToken(response.data.token);
    return response.data; // Assuming the token is returned in the response
}

export async function loginWithToken() {
  let response;
  try { 
    response = await axios.post(`${BACKEND_URL}/admin/login-token`, { token: getAuthToken() });
  } catch(err) {
    removeAuthToken();
    return { success: false };
  }
  if(response.data.token) setAuthToken(response.data.token);
  else removeAuthToken();
  return response.data;
}

// authService.js
export function setAuthToken(token) {
    localStorage.setItem('tunecastAuthToken', token);
  }
  
export function getAuthToken() {
return localStorage.getItem('tunecastAuthToken');
}

export function removeAuthToken() {
localStorage.removeItem('tunecastAuthToken');
}

  // authService.js
export const axiosInstance = axios.create({
//   baseURL: BACKEND_URL,
  headers: {
    'Authorization': `Bearer ${getAuthToken()}`
  }
});
