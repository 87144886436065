<template>
    <VisibilityModal 
        v-if="showVisibilityModal"
        @close="toggleVisibilityModal"
        @toggle="toggleStatus" 
    />
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem">
					<h1>Dashboard</h1>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Date</th>
							<th>Start Time</th>
							<th>Timezone</th>
							<th>Status</th>
							<th>Viewers</th>
							<th>Opt-Ins</th>
							<th>Stats</th>
							<th>Live Link</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(event, key) in tunecasts"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td class="title">{{event.name.slice(0,25) + (event.name.length > 25 ? '...' : '')}}</td>
							<td>{{event.start_date}}</td>
							<td>{{convertToAMPM(event.start_time)}}</td>
							<td>{{ getNiceTimezone(event.timezone) }}</td>
							<td>
								<label class="switch">
									<input 
										type="checkbox" 
										:checked="event.status === 'published'" 
										@change="() => {
											event.status = event.status === 'published' ? 'draft' : 'published';
											toggleStatus(event.id);
										}"
									>
									<span class="slider round"></span>
								</label>
							</td>
							<!-- <td @click="toggleVisibilityModal(event.id)">
								<button v-if="event.status === 'draft'" class="btn btn-draft">Draft</button>
								<button v-if="event.status === 'published'" class="btn btn-published">Published</button>
							</td> -->
							<td>{{event.viewers}}</td>
							<td>{{event.optins}}</td>
							<td><router-link :to="`/stats/${event.id}`" class="btn">View</router-link></td>
							<td><a :href="`https://${event.subdomain}.tunecast.com`" target="_blank" class="btn">Open</a></td>
							<td><router-link :to="`/tunecast/${event.id}`" class="btn">Edit</router-link></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { fetchTunecasts } from '@/services/tunecast-service';
import Nav from '../Nav';
import VisibilityModal from './components/Visibility.vue'
import tunecastService from '../../services/tunecast-service';

const showVisibilityModal = ref(false);
const selectedTunecast = ref(0);

function convertToAMPM(time24) {
  const [hours, minutes] = time24.split(':');
  const parsedHours = parseInt(hours, 10);
  
  if (parsedHours === 12) {
    return `${parsedHours}:${minutes} PM`;
  } else if (parsedHours === 0) {
    return `12:${minutes} AM`;
  } else if (parsedHours > 12) {
    return `${parsedHours - 12}:${minutes} PM`;
  } else {
    return `${parsedHours}:${minutes} AM`;
  }
}

function getNiceTimezone(str) {
	if(str.indexOf('Angeles') > -1) return 'PT';
	if(str.indexOf('York') > -1) return 'ET';
	if(str.indexOf('Japan') > -1) return 'JST';
	if(str.indexOf('London') > -1) return 'BST';
	if(str.indexOf('Paris') > -1) return 'CET';
}

// const getDate = (dateTime) => {
// 	let x = new Date(dateTime);
// 	return `${x.getMonth() + 1}/${x.getDate()}/${x.getFullYear()}`
// }

// const getTime = (dateTime) => {
// 	let x = new Date(dateTime);
// 	return `${x.getHours()}:${addPadding(x.getMinutes())}`
// }

// const addPadding = (num) => {
// 	return num < 10 ? '0' + num : num;
// }

const toggleVisibilityModal = (id) => {
    console.log('toggling?')
    showVisibilityModal.value = showVisibilityModal.value ? false : true;
    if(id) selectedTunecast.value = id;
};

const toggleStatus = async (id) => {
    // toggleVisibilityModal()
    await tunecastService.updateStatus(id);
    for(let j = 0; j < tunecasts.value.length; j++)
        if(tunecasts.value[j].id === selectedTunecast.value) {
            tunecasts.value[j].status = tunecasts.value[j].status === 'draft' ? 'published' : 'draft';
            break;
        }
        selectedTunecast.value = 0;
}

const tunecasts = ref([]);

onMounted(async () => {
	let data = await fetchTunecasts() ;
	tunecasts.value = data;
})
</script>
<style type="text/css" scoped>
/** SLIDER */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/** OTHER STUFF */
.table {
	width: 100%;
	border-collapse: collapse; 
}

.table th {
	text-align: left;
	color: #919bac;
	font-weight: 200;
	padding-bottom: .75rem;
}

.title {
	font-size: 20px;
	font-weight: 500;
}


.table td {
	padding: .75rem 0;
	border-top: 1px solid rgba(0,0,0,.1);
}


.btn {
	font-weight: 200;
	background-color: #000;
	color: #fff;
	padding: .35rem 1.25rem;
	border: none;
	font-size: 16px;
	border-radius: 3px;
	transition: .1s ease opacity;
}

.btn:hover {
	cursor: pointer;
	opacity: .8;
}

.btn-draft {
	color: #5e6a7a;
	font-size: 18px;
	font-weight: 600;
	background: rgba(94,106,122,.1);
}

.btn-published {
	color: #14d147;
	font-size: 18px;
	font-weight: 600;
    background-color: rgba(20,209,71,.1);
}
</style>