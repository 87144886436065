<template>
    <div>
        <Nav />
        <div class="container">
            <br /><br />
            <!-- to fix margin / padding issue, put in flex wrapper -->
            <div style="display: flex; flex-direction: 1;">
                <div class="card" style="flex: 1;">
                    <div class="card-body" style="margin: 2rem 2.5rem">
                        <h1>Analytics</h1>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <br />

                    <h3 v-if="tunecast && tunecast.name">
                        {{ tunecast && tunecast.name }}

                        <small style="margin:0 1rem; font-weight: 400">
                            {{ tunecast && tunecast.subdomain }}.tunecast.com
                        </small>

                        <router-link class="btn" style="padding: .5rem 2.2rem; background-color: #D9D9D9; color:#575757;" :to="`/tunecast/${tunecast.id}`">edit</router-link>
                    </h3>
                    <br />

                    <div style="display: flex; justify-content: space-between;">
                        <div class="quick-block">
                            <div style="position: absolute; bottom: 1rem; left: 1rem;">
                                <h2 style="margin: 0; color: #fff;">{{ stats && stats.dsps ? stats.dsps.spotify + stats.dsps.apple : '-'}}</h2>
                                <h4 style="margin: 0; color: #fff; font-weight: 200;">Unique Users</h4>
                            </div>
                        </div>
                        <div class="quick-block">
                            <div style="position: absolute; bottom: 1rem; left: 1rem;">
                                <h2 style="margin: 0; color: #fff;">{{stats && stats.dsps ? stats.total_sessions : '-'}}</h2>
                                <h4 style="margin: 0; color: #fff; font-weight: 200;">Total Sessions</h4>
                            </div>
                        </div>
                        <div class="quick-block">
                            <div style="position: absolute; bottom: 1rem; left: 1rem;">
                                <h2 style="margin: 0; color: #fff;">{{stats && stats.dsps ? ((Math.round(stats.avg_time / 10) / 100) / 60) + 'm ' : '-'}}</h2>
                                <h4 style="margin: 0; color: #fff; font-weight: 200;">Avg. Session Duration</h4>
                            </div>
                        </div>
                        <div class="quick-block">
                            <button class="btn-export">Export Data</button>
                            <div style="position: absolute; bottom: 1rem; left: 1rem;">
                                <h2 style="margin: 0; color: #fff;">{{ stats && stats.dsps ? stats.emails : '-' }}</h2>
                                <h4 style="margin: 0; color: #fff; font-weight: 200;">Emails Captured</h4>
                            </div>
                        </div>
                    </div>
                    <br />

                    <div style="display: flex; justify-content: space-between;">
                        <div class="card" style="width: 450px; box-shadow: 0 0 15px 0px rgba(0,0,0,.2)">
                            <div class="card-body">
                                <br />
                                <h2>Audience Top Countries</h2>
                                <br />
                                <table class="table" v-if="stats && stats.countries">
                                    <tr v-for="(c, key) in Object.keys(stats.countries)" :key="key">
                                        <td>{{ c }}</td>
                                        <td style="font-weight: 600">{{ stats.countries[c] }}</td>
                                        <td style="font-weight: 600">{{ Math.round(stats.countries[c] / stats.total_sessions * 10000) / 100 }}%</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="card" style="margin-left: 1.5rem; flex: 1; box-shadow: 0 0 15px 0px rgba(0,0,0,.2)">
                            <div class="card-body">
                                <br />
                                <h2>DSPs</h2>
                                <br />
                                <table class="table" v-if="stats && stats.countries">
                                    <tr>
                                        <td>Spotify</td>
                                        <td style="font-weight: 600">{{ stats.dsps.spotify }}</td>
                                        <!-- <td style="font-weight: 600">{{ Math.round(stats.countries[c] / stats.total_sessions * 10000) / 100 }}%</td> -->
                                    </tr>
                                    <tr>
                                        <td>Apple</td>
                                        <td style="font-weight: 600">{{ stats.dsps.apple }}</td>
                                        <!-- <td style="font-weight: 600">{{ Math.round(stats.countries[c] / stats.total_sessions * 10000) / 100 }}%</td> -->
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <br />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Nav from './Nav';
import { ref, onMounted } from 'vue';
import TunecastService from '@/services/tunecast-service';
import { useRoute } from 'vue-router';
const route = useRoute();
const tunecastId = route.params.tunecastId;
const tunecast = ref({})
const stats = ref({});

const fetchTunecast = TunecastService.fetchTunecast;
const fetchTunecastStats = TunecastService.fetchTunecastStats;
console.log('the id is', tunecastId);
onMounted(async () => {
    if(!tunecastId) return;
    console.log('mounting and fetching')
    let _tunecast = await fetchTunecast(tunecastId);
    let _stats = await fetchTunecastStats(tunecastId)
    tunecast.value = _tunecast;
    stats.value = _stats;
})

</script>

<style scoped>
.quick-block {
    background: linear-gradient(180deg, #5E16B0 4.17%, #E35DF9 100%);
    height: 6rem;
    width: 21.2%;
    position: relative;
    padding: 1rem;
    border-radius: 5px;
}

.table {
    width: 100%;
}

.table tr {
    display: block;
    border-top: 1px solid rgba(0,0,0,.1);
    width: 100%;
}
.table tr td {
    padding: .5rem .75rem;
    margin: 0;
}

.table tr td:nth-child(1) {
    width: 71%;
}

.table tr td:nth-child(2) {
    width: 15%;
}

.btn-export {
    position: absolute; 
    background-color:#D9D9D9;
    padding: .5rem 2rem;
    top: 1rem; 
    right: 1rem;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    transition: .2s ease all;
}

.btn-export:hover {
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0,0,0,.15);
}
</style>