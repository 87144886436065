<template>
  <router-view></router-view>
</template>

<script setup>
// import Create from './components/Create';
import router from './router';
import { loginWithToken } from './services/auth-service';
import { onBeforeMount } from 'vue';

onBeforeMount(async () => {
  let data = await loginWithToken();
  if(!data.success) router.push('/login');
})
</script>

<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*border: 0;*/
  /*padding: 0;*/
  /*margin: 0;*/
}
body {
  background: #f3f4f6;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.container {
  padding: 0 4rem;
}

.card {
  background-color: #fff;
  border-radius: 10px;
}

.card-body {
  margin: 2.5rem;
  position: relative;
}

h1,h2,h5 {
  margin: 0;
}
</style>
