<template>
  <div class="file-upload-container">
    <div
      ref="dropbox"
      :class="`drop-area ${dragOver ? 'drag' : ''}`"
      @dragover.prevent
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onFileDrop"
      @click="triggerFileInputClick"
      :style="previewUrl && `background-image: url(${previewUrl});`"
    >
      <p v-if="!selectedFile" style="color: #5A5A5A; display: flex; align-items: center;"><img src="/svgs/plus.svg" style="height: 1.25rem; margin-right: .35rem"> Upload image.</p>
      <p v-else style="color: #fff"> Edit Image</p>
    </div>
    <input
      type="file"
      style="display: none;"
      ref="fileInput"
      @change="onFileSelected"
    />
    <!-- <button @click="uploadFile" :disabled="!selectedFile">Upload</button> -->
  </div>
</template>

<script setup>
import { BACKEND_URL } from "@/services/config";
console.log('FILE DROP?!')
import { ref, defineEmits, defineProps, onMounted } from 'vue';

const props = defineProps(['thumbnail']);
const emit = defineEmits(['select']);

const fileInput = ref({});
const dropbox = ref({});

const selectedFile = ref(null);
const dragOver = ref(false);
const previewUrl = ref('');

onMounted(() => {
  previewUrl.value = props.thumbnail;
})

const triggerFileInputClick = () => {
  fileInput.value.click();
};


const uploadFile = async () => {
  if (!selectedFile.value) {
    alert("Please select a file first.");
    return;
  }

  // Your file upload logic here using fetch API
  const formData = new FormData();
  formData.append("file", selectedFile.value);

  // Assuming you have an endpoint to handle the file upload on the server
  let res;
  try {
    res = await fetch(`${BACKEND_URL}/file-upload`, {
      method: "POST",
      body: formData,
    });

    if(!res.ok)
      throw new Error('network response was not ok!');

    res = await res.json();
    return res;
  } catch(err) {
    console.error("Error uploading file:", err);
  }
};

const onDragOver = () => {
  console.log('the url is', BACKEND_URL)
  dragOver.value = true;
}

const onDragLeave = () => {
  dragOver.value = false;
}

const onFileSelected = (event) => {
  // Get the selected file from the input event
  selectedFile.value = event.target.files[0];
}

const onFileDrop = async (event) => {
  event.preventDefault();
  selectedFile.value = event.dataTransfer.files[0];
  // this.$emit('select', this.selectedFile)
  let res = await uploadFile();
  console.log('uploaded file', res);
  emit('select', res.file);
  previewUrl.value = res.file;
  // this.previewUrl = `${BACKEND_URL}/uploads/${res.file.split('/')[2]}`;
};

// export default {
//   props: ['thumbnail'],
//   data() {
//     return {
//       selectedFile: null,
//       dragOver: false,
//       previewUrl: ''
//     };
//   },
  // onMounted() {
  //   console.log('mounting file drop!', this.props.thumbnail)
  //   this.previewUrl = this.props.thumbnail;
  // }, 
  // watch: {
  //   thumbnail(newValue) {
  //     console.log('new value for thumbnail?', this.props.thumbnail)
  //     this.previewUrl = newValue;
  //   },
  // };
  // methods: {
// const triggerFileInputClick = () => {
//   fileInput.click();
// };

// onDragOver() {
//   console.log('the url is', BACKEND_URL)
//   this.dragOver = true;
// },

// onDragLeave() {
//   this.dragOver = false;
// },

// onFileSelected(event) {
//   // Get the selected file from the input event
//   this.selectedFile = event.target.files[0];
// },
// async onFileDrop(event) {
//   event.preventDefault();
//   this.selectedFile = event.dataTransfer.files[0];
//   // this.$emit('select', this.selectedFile)
//   let res = await this.uploadFile();
//   console.log('uploaded file', res);
//   this.$emit('select', res.file);
//   this.previewUrl = res.file;
//   // this.previewUrl = `${BACKEND_URL}/uploads/${res.file.split('/')[2]}`;
// },
// async uploadFile() {
//   if (!this.selectedFile) {
//     alert("Please select a file first.");
//     return;
//   }

//   // Your file upload logic here using fetch API
//   const formData = new FormData();
//   formData.append("file", this.selectedFile);

//   // Assuming you have an endpoint to handle the file upload on the server
//   let res;
//   try {
//     res = await fetch(`${BACKEND_URL}/file-upload`, {
//       method: "POST",
//       body: formData,
//     });

//     if(!res.ok)
//       throw new Error('network response was not ok!');

//     res = await res.json();
//     return res;
//   } catch(err) {
//     console.error("Error uploading file:", err);
//   }
// },
// }
// };
</script>

<style>
.file-upload-container {
  text-align: center;
}

.drop-area {
  /*border: 2px dashed #ccc;*/
  background-color: #EBEBEB;
  /*padding: .5rem;*/
  height: 14rem;
  cursor: pointer;
  transition: .2s ease background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.drag {
  background-color: #d9d9d9;
}
</style>