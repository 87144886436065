<template>

	<div class="container" style="max-width: 600px;margin: auto;">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Admin Login</h1>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
                <div v-if="error">
                    <p style="color: red">Invalid username / password combination</p>
                </div>
				<div class="form-group">
                    <label for="">Username</label>
                    <input type="text" class="form-control" placeholder="username" v-model="username">
                </div>
				<div class="form-group">
                    <label for="">Password</label>
                    <input type="password" class="form-control" placeholder="password" v-model="password">
                </div>
                <br />
                <button class="btn" style="width: 100%" @click="submit">Submit</button>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { ref } from 'vue';
import {login} from '../services/auth-service';
// import { BACKEND_URL } from "@/services/config";
import router from '@/router';


const username = ref('');
const password = ref('');
const error = ref(false);

const submit = async () => {
    console.log(username.value, password.value)
    let res;
    try {
        // res = await axios.post(`${BACKEND_URL}/admin/login`, { username: username.value, password: password.value });
        res = await login(username.value, password.value);
        if(!res.success) return error.value = true;
        error.value = false;
        router.push('/');
    } catch(err) {
        return error.value = true;
    }
}

</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}

.form-group label {
	font-weight: 500;
	display: block;
	margin: .8rem 0;
	font-size: 17px;
}

.form-control {
	background: #EBEBEB;
	border: none;
	display: block;
	padding: 1rem 1.5rem;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}
</style>