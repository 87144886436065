<template>
<div>
	<Nav />
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem">
					<h1 v-if="!tunecastId">Create a Tunecast</h1>
					<h1 v-else>Editing <i>{{ tunecastName }}</i></h1>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1; column-gap: 1rem;">
			<div class="card" style="width: 20rem">
				<div class="card-body list-items">
					<div id="item-line"></div>

					<div
						v-for="(page, key) in data"
						:class="currentPage === key && 'active'"
						:key="key"
					>
						<h3 @click="selectPage(key)">

							<span class="list-item-circle"></span>
							<span>{{page.title}}</span>
						</h3>

						<div v-if="currentPage === key">
							<ul>
								<li 
									v-for="(input, key) in filterInputs(page.inputs)"
									:key="key"
									:style="input.completed && `color: #9D53F1`"
								>{{input.title || input.label}}</li>
							</ul>
						</div>
					</div>

				</div>
			</div>


			<div class="card" style="flex: 1">
				<div class="card-body">
					<div :class="data[currentPage].flex && 'form-flex'">
					<div class="form-group"	
						v-for="(input, key) in data[currentPage].inputs"
						:key="key"
					>
						<label :style="!submittable && input.required && !input.value ? 'color: red' : ''">{{input.label}}</label>
						<p 
							style="font-weight: 200; font-size: 15px" 
							v-if="input.description"
						>
							{{input.description}}
						</p>

						<input 
							type="text" 
							:placeholder="input.placeholder"
							class="form-control"
							v-if="input.type === 'input'"
							v-model="input.value"
						>

						<input 
							type="date" 
							:placeholder="input.placeholder"
							class="form-control"
							v-if="input.type === 'date'"
							v-model="input.value"
						>

						<input 
							type="time" 
							:placeholder="input.placeholder"
							class="form-control"
							v-if="input.type === 'time'"
							v-model="input.value"
						>

						<input 
							type="color" 
							:placeholder="input.placeholder"
							class="form-control"
							style="padding: 0; height: 3.38rem;"
							v-if="input.type === 'color'"
							v-model="input.value"
						>

						<select
							v-if="input.type === 'select'"
							class="form-control"
							v-model="input.value"
							:placeholder="input.placeholder"
						>
							<option
								v-for="(option, key) in input.options"
								:key="key"
								:value="option.value"
								:disabled="option.value === ''"
							>{{option.label}}</option>
						</select>

						<FileDrop 
							v-if="input.type === 'file'"
							:thumbnail="input.value"
							@select="file => onSelectFile(input, file)"
						/>

						<textarea 
							type="text" 
							:placeholder="input.placeholder"
							class="form-control"
							v-if="input.type === 'textbox'"
							:rows="input.rows"
							v-model="input.value"
						></textarea>
					</div>
					</div>


					<br /><br /><br />

					<button 
						class="btn btn-next" 
						@click="next"
						v-if="currentPage !== data.length-1"
					>
						Next
					</button>
					<button 
						class="btn btn-next" 
						@click="submit"
						v-else
					>
						<span v-if="!isSubmitting">Submit</span>
						<span v-if="isSubmitting">Loading{{ dots }}</span>
					</button>
				</div>
			</div>

		</div>
		<br /><br />
	</div>
</div>
</template>
<script setup>
import router from '@/router';
import Nav from './Nav';
import { ref, onMounted } from 'vue';
import FileDrop from './FileDrop';
import TunecastService from '@/services/tunecast-service';
import { useRoute } from 'vue-router';
const route = useRoute();
const tunecastId = route.params.tunecastId;

const isSubmitting = ref(false);


const create = TunecastService.create;
const fetchTunecast = TunecastService.fetchTunecast;

const currentPage = ref(0);
const submittable = ref(true);

const dots = ref('.');
setInterval(() => {
    let ln = dots.value.length >= 3  ? 1 : dots.value.length + 1; 
    let str = '';
    for(let j =0; j < ln; j++)
        str += '.';
    dots.value = str;
}, 1000);

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */

const data = ref([
	{
		title: 'General Info',
		inputs: [
			{
				name: 'name',
				required: true,
				label: 'Name (Internal Only)',
				title: 'Name', // if different from label
				type: 'input',
				placeholder: 'Title',
				value: ''
			},
			{
				name: 'subdomain',
				required: true,
				label: 'Subdomain URL',
				type: 'input',
				placeholder: '.tunecast.com',
				value: ''
			},
			{
				name: 'lang',
				placeholder: 'Language',
				required: true,
				label: 'Language',
				type: 'select',
				options: [{value: '', label: 'Select Language'}, {value: 'en', label: 'English'}, {value: 'es', label: 'Spanish'}, {value: 'jp', label: 'Japanese'},{value: 'fr', label: 'French'}],
				description: 'Use a language preset to update language specific text within the Tunecast. Presets have been provides, but can be customized by changing the text in the inputs below.',
				value: ''

			},

			{
				name: 'custom_terms',
				placeholder: 'Custom Terms of Service',
				required: false,
				label: 'Terms Link',
				type: 'input',
				value: ''
			},

			{
				name: 'custom_privacy',
				placeholder: 'Custom Privacy',
				required: false,
				label: 'Privacy Link',
				type: 'input',
				value: ''
			}
		]
	},

	{
		title: 'Images',
		flex: true,
		inputs: [
			{
				label: 'Title (Visible on Tunecast)',
				title: 'Title',
				name: 'name_public',
				type: 'input',
				placeholder: 'Title',
				required: true,
				value: ''
			},
			{
				label: 'Title Text Color',
				title: 'Title',
				type: 'color',
				placeholder: 'Title',
				value: ''
			},
			{
				label: 'Logo Image',
				type: 'file',
				name: 'home_logo_image',
				value: '',
				paired: false
			},
			{
				label: 'Background Image',
				type: 'file',
				name: 'home_background_image',
				value: '',
				paired: false
			},
			{
				label: 'Chat Logo',
				type: 'file',
				name: 'chat_logo_image',
				value: '',
				paired: false
			},
			{
				label: 'Visualizer / Artwork',
				type: 'file',
				name: 'chat_artwork_image',
				value: '',
				paired: false
			}
		]
	},

	{
		title: 'Custom CSS',
		inputs: [
			{
				name: 'css',
				label: 'Custom CSS',
				type: 'textbox',
				placeholder: '.wrapper { background-color: red }',
				rows: 12,
				value: ''
			}
		]
		
	},

	{
		title: 'Scheduling',
		flex: true,
		inputs: [
			{
				label: 'Start Date',
				name: "start_date",
				type: 'date',
				placeholder: 'mm/dd/yyyy',
				required: true,
				value: ''
			},
			{
				label: 'Start Time',
				name: "start_time",
				type: 'time',
				// placeholder: 'mm/dd/yyyy',
				required: true,
				value: ''
			},

			{
				label: 'End Date',
				name: "end_date",
				type: 'date',
				placeholder: 'mm/dd/yyyy',
				value: ''
			},
			{
				label: 'End Time',
				name: "end_time",
				type: 'time',
				// placeholder: 'mm/dd/yyyy',
				value: ''
			},


			{
				label: 'Timezone',
				name: "timezone",
				required: true,
				type: 'select',
				value: '',
				paired: false,
				options: [
					{label: 'Select Timezone', value: ''},
					{label: 'US Pacific Time (PST/PDT)', value: 'America/Los_Angeles'}, 
					{label: 'US Eastern Time (EST/EDT)', value: 'America/New_York'},
					{label: 'London Time (BST)', value: 'Europe/London'}, 
					{label: 'Central Europe Time (CET)', value: 'Europe/Paris'},
					{label: 'Japanese Standard Time (JST)', value: 'Asia/Tokyo'}
				]
			},

			{
				label: 'Loop?',
				name: "loop",
				type: 'select',
				required: true,
				paired: false,
				value: '',
				options: [
					{label: 'Yes or no', value: ''},
					{label: 'Yes', value: true},
					{label: 'No', value: false}
				]
			},
		]
		
	},

	{
		title: 'Playlist',
		// flex: true,
		inputs: [
			{
				label: 'Spotify Playlist',
				required: true,
				name: "spotify_playlist",
				type: 'input',
				placeholder: 'spotify:playlist:0rxZbIVBopeQZXEWN4vYDs',
				value: ''
			},
			{
				label: 'Streaming Goal',
				name: 'streaming_goal',
				type: 'input',
				placeholder: '1000000',
				value: ''
			}
		]
	},

	{
		title: 'Moderation',
		inputs: [
			{
				label: 'Moderator Code; Entered in chat via modcode:<code>',
				title: 'Moderator Code',
				required: true,
				name: 'mod_code',
				type: 'input',
				placeholder: 'mysupercoolcode'
			},
			{
				label: 'Blocked Words List',
				name: 'profanity_list',
				type: 'textbox',
				rows: 5,
				placeholder: 'bad,words,separated,by,commas',
				value: ''
			}
		]
	},

	{
		title: 'Social Media',
		flex: true,
		inputs: [
			{
				label: 'Instagram',
				name: 'instagram_link',
				type: 'input',
				value: ''
			},
			{
				label: 'Twitter',
				name: 'twitter_link',
				type: 'input',
				value: ''
			},
			{
				label: 'Facebook',
				name: 'facebook_link',
				type: 'input',
				value: ''
			},
			{
				label: 'Snapchat',
				name: 'snapchat_link',
				type: 'input',
				value: ''
			},
			{
				label: 'Discord',
				name: 'discord_link',
				type: 'input',
				value: ''
			},
		]
	},

	{
		title: 'Shopify',
		flex: true,
		inputs: [
			{
				label: 'Shopify Domain',
				name: 'shopify_domain',
				type: 'input',
				placeholder: 'xbcd8-al2f-492jm1q-291ka',
				value: '',
				paired: false
			},

			{
				label: 'Shopify Token',
				name: 'shopify_token',
				type: 'input',
				placeholder: 'x8bc-182jdjd-laKjska',
				value: '',
				paired: false
			},

			{
				label: 'Resource ID',
				name: 'shopify_resource',
				type: 'input',
				placeholder: 'resource://shopifyCollection-281UI91-DCCO02P-291',
				value: '',
				paired: false
			},

			{
				label: 'Resource Type',
				name: 'shopify_resource_type',
				type: 'input',
				placeholder: 'Something?',
				value: '',
				paired: false
			}
		]
	},

	{
		title: 'CMS',
		inputs: [
			{
				label: 'Optin Text',
				name: 'optin_text',
				type: 'textbox',
				rows: 3,
				value: 'Emails will be sent by or on behalf of Universal Music Group 2220 Colorado Avenue, Santa Monica , CA 90404 (310) 865-4000. You may withdraw your consent at any time. Privacy Policy / Do Not Sell My Personal Information',
				paired: false
			},

			{
				label: 'Collect Numbers',
				name: 'optin_mobile_enabled',
				options: [{label: 'Yes', value: true},{label: 'No', value: false}],
				type: 'select',
				value: true,
				paired: false
			},

			{
				label: 'Collect Emails',
				name: 'optin_email_enabled',
				options: [{label: 'Yes', value: true},{label: 'No', value: false}],
				type: 'select',
				value: true,
				paired: false
			},

			{
				label: 'Optin Lists',
				title: 'Optin Lists (separated by comma)',
				name: 'optin_lists',
				type: 'input',
				// rows: 3,
				placeholder: 'UMG,Artist Name,Other List',
				value: '',
				paired: false
			},
		]
	}
])

const next = () => {
	currentPage.value++;
}

const submit = async () => {
	if(isSubmitting.value) return;
	isSubmitting.value = true;
	let payload = {
		...tunecastId ? {id: tunecastId} : {}
	};
	submittable.value = true;
	data.value.forEach(block => {
		block.inputs.forEach(input => {
			if(!input.name) return;
			if(input.required && !input.value) return submittable.value = false;
			payload[input.name] = input.value;
		})
	})

	if(!submittable.value) return isSubmitting.value = false;
	// submit payload
	try {
		await create(payload);
		isSubmitting.value = false;
		router.push('/');
	} catch(err) {
		isSubmitting.value = false;
		alert('Error creating tunecast...');
	}
}

const selectPage = index => {
	currentPage.value = index;
}

const filterInputs = inputs => {
	let newInputs = inputs.map((i, key) => {
		console.log(i.paired)
		if(data.value[currentPage.value].flex && i.paired !== false && key % 2 !== 0) return;

		/**
		 * if this is a flex form...
		 * we check the current inputs value and the next one
		 * because they are paired
		 */
		return ({
			...i,
			completed: data.value[currentPage.value].flex && i.paired !== false ? (!!i.value && (key + 1 === inputs.length || !!inputs[key + 1].value)) : !!i.value
		})
	})

	return newInputs.filter((i, key) => {
		console.log(i)
		return !data.value[currentPage.value].flex || (i && i.paired === false) ||  key % 2 === 0;
	});
}

const onSelectFile = (input, filePath) => {
	console.log('setting the value!',input, filePath)
	input.value = filePath;
}

// const isFinished = (inputs) => {
// 	const _inputs = inputs.map(i => i.completed);
// 	if(_inputs.indexOf(false) > -1) return false;
// 	return true;
// }

const tunecastName = ref('');

onMounted(async () => {
    if(!tunecastId) return;
    let res = await fetchTunecast(tunecastId);
	tunecastName.value = res.name;
    Object.keys(res).forEach(key => {
        // data.value[0].inputs.find(i => i.name === 'schoolId').value = res.school;
        // data.value[0].inputs.find(i => i.name === 'sportId').value = res.sport;
        let input;
		for(let j = 0; j < data.value.length; j++) {
			input = data.value[j].inputs.find(i => i.name === key);
			if(!input) continue;
			break;
		}
			
        if(!input) return;
        console.log('found input', key, input, res[key])
        input.value = res[key];
    })
})

</script>
<style type="text/css" scoped>

/**
 * SIDEBAR
 */

#item-line {
	position: absolute;
	width: 3.5px;
	height: 100%;
	left: .52rem;
	background-color: #000;
}

.list-items {
	position: relative;
	/*height: 200px;*/
	overflow: hidden;
}

.list-items h3 {
	font-size: 17px;
	display: flex; 
	align-items: center;
}

.list-items h3 span:nth-child(2) {
	transition: .1s ease opacity;
}

.list-items h3:hover { cursor: pointer; }

.list-items h3:hover span:nth-child(2) { opacity: .7; }

.list-items h3.past {
	color: #9D53F1;
}

.list-items h3.past .list-item-circle {
	background: #9D53F1;
}

.list-items .active h3 {
	color: #5E16B0;
	font-size: 20px;
}

.list-items .active h3 .list-item-circle {
	background: #5E16B0;
}

.list-items ul {
	padding-bottom: .25px;
}
.list-items ul li {
	list-style-type: none;
	color: #5A5A5A;
	font-weight: 200;
	margin-bottom: .75rem;
	font-size: 15px;
}

.list-item-circle {
	position: relative;
	z-index: 5;
	display: inline-block; 
	width: 1.3rem; 
	height: 1.3rem; 
	background-color: #000; 
	border-radius: 54%;
	margin-right: .8rem;
}

/**
 * FORM SECTION
 */
.form-flex {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.form-flex .form-group {
	min-width: 49%;
}
.form-group label {
	font-weight: 500;
	display: block;
	margin: .8rem 0;
	font-size: 17px;
}

.form-control {
	background: #EBEBEB;
	border: none;
	display: block;
	padding: 1rem 1.5rem;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5A5A5A;
  opacity: 1; /* Firefox */
}

.form-group {
	padding-bottom: .5rem;
}

.btn-next {
	font-weight: 200;
	background-color: #000;
	color: #fff;
	padding: .5rem 3.5rem;
	position: absolute;
	right: -1rem;
	bottom: -.25rem;
	border: none;
	font-size: 16px;
	border-radius: 3px;
	transition: .1s ease opacity;
}

.btn-next:hover {
	cursor: pointer;
	opacity: .8;
}
</style>